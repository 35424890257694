import {Tag} from "antd";

const byte = 1;
const KB = 1024 * byte;
const MB = 1024 * KB;
const GB = 1024 * MB;
const TB = 1024 * GB;
const PB = 1024 * TB;
const EB = 1024 * PB;

const bit = byte * 8;
const Kb = 1024 * bit;
const Mb = 1024 * Kb;
const Gb = 1024 * Mb;
const Tb = 1024 * Gb;
const Pb = 1024 * Tb;
const Eb = 1024 * Pb;

export {bit, Kb, Mb, Gb, Tb, Pb, Eb}


export {byte, KB, MB, GB, TB, PB, EB}


const FormatSize = (size) => {
    if (size === undefined || size < 0) {
        return '——';
    } else if (size < KB) {
        return size.toFixed(2) + ' B';
    } else if (size < MB) {
        return (size / KB).toFixed(2) + ' KB';
    } else if (size < GB) {
        return (size / MB).toFixed(2) + ' MB';
    } else if (size < TB) {
        return (size / GB).toFixed(2) + ' GB';
    } else if (size < PB) {
        return (size / TB).toFixed(2) + ' TB';
    } else {
        return (size / PB).toFixed(2) + ' PB';
    }
}

const FormatSpeed = (speed) => {
    if (speed === undefined || speed < 0) {
        return '——';
    } else if (speed < Kb / 8) {
        return (speed * 8).toFixed(2) + ' bps';
    } else if (speed < Mb / 8) {
        return (speed * 8 / Kb).toFixed(2) + ' Kbps';
    } else if (speed < Gb / 8) {
        return (speed * 8 / Mb).toFixed(2) + ' Mbps';
    } else if (speed < TB / 8) {
        return (speed * 8 / GB).toFixed(2) + ' Gbps';
    } else if (speed < Pb / 8) {
        return (speed * 8 / Tb).toFixed(2) + ' Tbps';
    }
}

const TagDownload = (download) => {
    if (download === undefined || download <= 0) {
        return <></>
    } else if (download > MB * 3) {
        return <Tag color={'#c0d09d'}>{FormatSpeed(download)}</Tag>
    } else if (download > MB) {
        return <Tag color={'#d6c560'}>{FormatSpeed(download)}</Tag>
    } else {
        return <Tag color={'#cf929e'}>{FormatSpeed(download)}</Tag>
    }
}

const TagUpload = (upload) => {
    if (upload === undefined || upload < 0) {
        return <Tag color={'#cf929e'}>0 B/s</Tag>
    } else if (upload > MB * 3) {
        return <Tag color={'#c0d09d'}>{FormatSpeed(upload)}</Tag>
    } else if (upload > MB) {
        return <Tag color={'#d6c560'}>{FormatSpeed(upload)}</Tag>
    } else {
        return <Tag color={'#cf929e'}>{FormatSpeed(upload)}</Tag>
    }
}

export {FormatSpeed, FormatSize, TagDownload, TagUpload};

const Nanosecond = 1;
const Microsecond = 1000 * Nanosecond;
const Millisecond = 1000 * Microsecond;
const Second = 1000 * Millisecond;
const Minute = 60 * Second;
const Hour = 60 * Minute;
const Day = 24 * Hour;

export {Nanosecond, Microsecond, Millisecond, Second, Minute, Hour, Day};

const FormatDuration = (duration) => {
    if (duration === undefined || duration < 0) {
        return '——';
    } else if (duration < Microsecond) {
        return duration.toFixed(2) + ' ns';
    } else if (duration < Millisecond) {
        return (duration / Microsecond).toFixed(2) + ' μs';
    } else if (duration < Second) {
        return (duration / Millisecond).toFixed(2) + ' ms';
    } else if (duration < Minute) {
        return (duration / Second).toFixed(2) + ' s';
    } else if (duration < Hour) {
        return (duration / Minute).toFixed(2) + ' m';
    } else if (duration < Day) {
        return (duration / Hour).toFixed(2) + ' h';
    } else {
        return (duration / Day).toFixed(2) + ' d';
    }
}

const TagDelay = (delay) => {
    if (delay === undefined || delay <= 0) {
        return <></>
    } else if (delay < Millisecond * 500) {
        return <Tag color={'#c0d09d'}>{FormatDuration(delay)}</Tag>
    } else if (delay < Second) {
        return <Tag color={'#d6c560'}>{FormatDuration(delay)}</Tag>
    } else {
        return <Tag color={'#cf929e'}>{FormatDuration(delay)}</Tag>
    }
}

export {FormatDuration, TagDelay};

const FormatTimestamp = (timestamp) => {
    if (timestamp === undefined || timestamp < 0) {
        return '——';
    }

    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return `${year}年${month}月${day}日 ${hour}:${minute}:${second}`;
}

export {FormatTimestamp}
