import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import React, {useEffect, useState} from "react";
import taskCheck from "./pages/task/taskCheck";
import cookie from "react-cookies";
import axios from "axios";
import {notification, Tag} from "antd";
import {PageContainer, ProLayout} from "@ant-design/pro-components";

function App() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [refreshAt, setRefreshAt] = useState(new Date().getTime());

    const loadHitokoto = () => {
        if (process.env.NODE_ENV === 'development') {
            return
        }

        if (refreshAt < new Date().getTime() - 1000 * 1200) {
            return
        }

        axios.get('https://v1.hitokoto.cn')
            .then((response) => {
                setRefreshAt(new Date().getTime())
                if (response.data === undefined) {
                    return
                }

                if (response.data.hitokoto === undefined) {
                    return
                }

                cookie.save('hitokoto-body', response.data.hitokoto)
                cookie.save('hitokoto-from', response.data.from)
                setRefreshAt(new Date().getTime())
            })
            .catch((error) => {
                setRefreshAt(new Date().getTime())
                console.log(error)
            })
    }
    /* eslint-disable */
    useEffect(() => {
        loadHitokoto()
    }, [])
    /* eslint-enable */

    notification.config({
        duration: 3,
        maxCount: 3,
    })

    let hitokotoBody = cookie.load('hitokoto-body')
    let hitokotoFrom = cookie.load('hitokoto-from')

    if (hitokotoBody === undefined || hitokotoBody === null) {
        hitokotoBody = '人总是贪婪的，就像最开始，我也只是想知道你的名字。'
        hitokotoFrom = '你的名字'
    }

    return (
        <BrowserRouter>
            <ProLayout
                token={{
                    header: {
                        heightLayoutHeader: 108,
                    },
                }}
                // menu={{
                //     locale: false,
                //     type: 'group',
                //     request: (params, defaultMenuData) => {
                //         return Promise.resolve(menu())
                //     },
                // }}
                contentWidth={'Fixed'}
                layout={'mix'}
                // avatarProps={{
                //     title: cookie.load('name'),
                //     src: (<UserOutlined/>),
                //     style: {
                //         backgroundColor: '#9b8ea9',
                //     },
                //     onClick: () => {
                //         window.location.href = '/app/user/info'
                //     }
                // }}

                // 点击头像的事件
                // onTopMixMenuHeaderClick={() => {
                //     window.location.href = '/app/user/info'
                // }}

                // 头菜单
                // headerContentRender={props => {
                // }}

                // actionsRender={(props) => {
                //     return [
                //         <LogoutOutlined
                //             style={{color: '#f0908d'}}
                //             title={'退出登录'}
                //             onClick={onLogout}/>
                //     ]
                // }}
                menuFooterRender={(props) => {
                    return (
                        <div
                            style={{
                                textAlign: 'center',
                                paddingBlockStart: 12,
                            }}
                        >
                            <p id="hitokoto">
                                {hitokotoBody}
                                <br/>
                                <Tag color={'#9b8ea9'}>
                                    {hitokotoFrom}
                                </Tag>
                            </p>
                            <div>© 2023 Made with love</div>
                            <div>by etsuko</div>
                        </div>
                    );
                }}

                headerTitleRender={(logo, title, props) => {
                    return (
                        <Link to={'/'} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: 30,
                            color: '#f6bec8',
                            fontWeight: 'bold',
                        }}>
                            etsuko
                        </Link>
                    )
                }}

                // 菜单头
                // menuHeaderRender={(logo, title) => {
                //     return (
                //         <Menu
                //             forceSubMenuRender
                //             inlineCollapsed={false}
                //             triggerSubMenuAction={'hover'}
                //             // selectedKeys={[current]}
                //             mode="inline"
                //             items={menu()}
                //         />
                //     )
                // }}

                // 菜单
                // menuRender
            >
                <PageContainer>
                    <Routes>
                        <Route path="/task/check/detail" Component={taskCheck}/>
                    </Routes>
                </PageContainer>

            </ProLayout>

        </BrowserRouter>
    )
}

export default App;
