import axios from "axios";
import cookie from "react-cookies";
import {notification} from "antd";

const header = () => {
    return {
        'Content-Type': 'application/json',
        'X-Client': 'web',
        'X-Session-Id': cookie.load('token'),
    }
}

export function PostAsync(path, body, callback: (data, error) => void) {
    axios.post(process.env.REACT_APP_API_URL + path, body, {
        'headers': header()
    }).then(resp => {
        if (resp.status !== 200) {
            if (callback !== null && callback !== undefined) {
                callback(null, {
                    code: resp.status,
                    msg: resp.statusText,
                })
            }
        } else if (resp.data.code !== 0) {
            if (callback !== null && callback !== undefined) {
                callback(null, {
                    code: resp.data.code,
                    msg: resp.data.msg,
                })
            }
        } else {
            console.log(resp.data.data)
            if (callback !== null && callback !== undefined) {
                callback(resp.data.data, null)
            }
        }
    }).catch((error) => {
        const resp = error.response
        if (resp.status !== 200) {
            if (callback !== null && callback !== undefined) {
                callback(null, {
                    code: resp.status,
                    msg: resp.statusText,
                })
            }
        }
    })
}

// 通过 axios 同步进行 post 请求并且返回 data 和 error
export async function PostSync(path: string, body: object) {
    return await axios.post(process.env.REACT_APP_API_URL + path, body, {
        'headers': header()
    }).then(resp => {
        if (resp.status !== 200) {
            return {
                data: null,
                error: {
                    code: resp.status,
                    msg: resp.statusText,
                }
            }
        } else if (resp.data.code !== 0) {
            return {
                data: null,
                error: {
                    code: resp.data.code,
                    msg: resp.data.msg,
                }
            }
        } else {
            console.log(resp.data.data)
            return {
                data: resp.data.data,
                error: null,
            }
        }
    }).catch((error) => {
        let resp = error.response
        if (resp.status !== 200) {
            return {
                data: null,
                error: {
                    code: resp.status,
                    msg: resp.statusText,
                }
            }
        }
    })
}
