import React, {useEffect, useState} from "react";
import {PostAsync} from "../../utils/post";
import qs from "query-string";
import {message, Table} from "antd";
import Column from "antd/es/table/Column";
import {TagDelay, TagDownload} from "../../utils/unit";
import {TinyArea} from "@ant-design/plots";

function taskCheck() {
    const params = qs.parse(window.location.search);
    const [messageApi, contextHolder] = message.useMessage();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, setState] = useState({
        task_type: undefined,
        start_time: undefined,
        end_time: undefined,
        list: [],
    })

    /* eslint-disable */
    useEffect(() => {
        if (params.id === undefined || params.chat_id === undefined || params.task_id === undefined) {
            messageApi.error("无效的任务")
            return
        }

        setLoading(true)
        PostAsync('/api/task/check/task', {
            'id': Number(params.id),
            'chat_id': Number(params.chat_id),
            'task_id': params.task_id,
        }, (data, error) => {
            setLoading(false)

            if (error !== null) {
                messageApi.error("加载数据失败")
                return
            }

            setState(data)
        })
    }, [])
    /* eslint-enable */

    switch (state.task_type) {
        case 2:
            return (
                <>
                    {contextHolder}
                    <Table
                        dataSource={state.list}
                        bordered={true}
                        pagination={false}
                        showSorterTooltip={true}
                    >
                        <Column
                            ellipsis={true}
                            title={'名称'}
                            dataIndex={'name'}
                        />
                        <Column
                            ellipsis={true}
                            title={'协议'}
                            dataIndex={'protocol'}
                        />
                        <Column
                            defaultSortOrder={'ascend'}
                            title={'延时'}
                            dataIndex={'delay'}
                            render={(value, record, index) => {
                                return TagDelay(value)
                            }}
                        />
                        <Column
                            defaultSortOrder={'descend'}
                            title={'平均速度'}
                            dataIndex={'speed_record_list'}
                            render={(value, record, index) => {
                                if (value === undefined || value === null || value.length === 0) {
                                    return <></>
                                }

                                let sum = 0;
                                for (let i = 0; i < value.length; i++) {
                                    sum += value[i]
                                }

                                return TagDownload(sum / value.length)
                            }}
                        />
                        <Column
                            defaultSortOrder={'descend'}
                            title={'最快速度'}
                            dataIndex={'speed_record_list'}
                            render={value => {
                                if (value === undefined || value === null || value.length === 0) {
                                    return <></>
                                }

                                let max = 0
                                for (let i = 0; i < value.length; i++) {
                                    if (value[i] > max) {
                                        max = value[i]
                                    }
                                }

                                return TagDownload(max)
                            }}
                        />
                        <Column title={'速度曲线'} dataIndex={'speed_record_list'} render={value => {
                            return <TinyArea
                                data={value}
                                autoFit={false}
                                height={60}
                                smooth={true}
                                renderer={'svg'}
                                areaStyle={{
                                    fill: '#eebfc8',
                                }}
                            />
                        }}/>
                    </Table>
                </>
            )
        default:
            return (
                <div>
                    {contextHolder}
                    <Table
                        loading={loading}
                    >

                    </Table>
                </div>
            );
    }
}

export default taskCheck;
