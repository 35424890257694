import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { StyleProvider} from '@ant-design/cssinjs';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from "antd";

// https://ant.design/theme-editor-cn
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <StyleProvider hashPriority="high" >
      <ConfigProvider
          theme={{
              token: {
                  colorPrimary: "#f6bec8"
              },
          }}
      >
          <App />
      </ConfigProvider>
      </StyleProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
